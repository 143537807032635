.project-menu {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 3rem;
    margin-bottom: 15rem;
    margin-left: 7rem;
    margin-right: 7rem;
    word-spacing: 0.1rem;
    letter-spacing: 0.5px;
    
}

.project-abbre {
    font-family: 'Lato';
    font-size: 1rem;
    font-weight: 300;
    text-transform: uppercase;
    color: var(--pink);
}

.project-items-subcontainer {
    background: var(--dark-purple);
    border-radius: 2rem;
}


.project-sub-container-inner-border {
    margin: 2rem;
}

.project-title {
    font-family: 'Poppins';
    font-size: 1.2rem;
    color: var(--orange);
}

.highlights-heading {
    font-family: 'Lato';
    font-weight: 300;
    text-transform: uppercase;
}

.github-project-icon{
    width:2rem;
    transition: 200ms;
}

.github-project-icon:hover{
    filter: hue-rotate(100deg) saturate(5);
    
}





@media only screen and (min-width: 1100px){
    .project-menu {
        transform: translateX(3rem);
    }

    .project-items-container {
        flex-direction: column;
        display: flex;
        position: relative;
        width: 25vw;
        right:2rem;
        align-items: flex-end;
    }

    .project-items-subcontainer {
        width: 50vw;
        
    }

    

    .project-item {
        height: 3.75rem;
        width: 90%;
        display: flex;
        position: relative;
        justify-content: flex-end;
        align-items: center;
        cursor: pointer;
        
    }

    .project-abbre {
        transition: 200ms;
    }

    .project-item:hover .project-abbre {
        margin-right: 2rem;
    }

    .activeProject .project-abbre {
        font-size: 2rem;
        color: white;
        margin-right: 2rem;
        transition: 400ms;
    }

    .activeProject{
        border-top-right-radius: 4rem;
        border-bottom-right-radius: 1rem;
        cursor: default;
        background: linear-gradient(270deg,var(--orange)10%, #ffffff00 60%);;
    }



    
    .project-image {
        margin-top:2rem;
        width: 30rem;
        height: 15rem;
    }
}

@media only screen and (max-width: 1100px){

    .project-menu {
        flex-direction: column;
        align-items: center;
        
    }
    .project-items-container {
        flex-direction: column;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        width: 99vw;
        margin-bottom: 2rem;
        
    }

    .project-item {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 20rem;
        height: 3rem;
        text-align: center;
    }

    .project-items-subcontainer {
        left: 5rem;
    }

    .project-abbre {
        transition: 200ms;
    }

    .project-item:hover .project-abbre {
        transform: translateY(-0.5rem);
    }

    .activeProject .project-abbre {
        
        font-size: 1.5rem;
        color: white;
        transition: 400ms;
    }

    .activeProject{
        
        cursor: default;
        background: linear-gradient(90deg,#ffffff00 , var(--orange) 50%, #ffffff00);
    }

    .project-image {
        width: 20rem;
        height: 10rem;
    }
}