@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300&family=Poppins:wght@700&display=swap');

:root {
    --pink: #ECCCFF;
    --pink-transparent: #ffd6ea40;
    --dark-grey: #282828;
    --dark-purple: #292427;
    --dark-purple-transparent: #292427b2;
    --trasparent: #000000;
    --orange: #ffb510;
    --orange-transparent: #ffb31079;
    --radius-left: 3rem 0 0 3rem;
    --radius-right: 0 3rem 3rem 0;
}

body {
    margin: 0;
    padding: 0;
    color: white;
    -webkit-tap-highlight-color: transparent;
    background-color: var(--dark-grey);
}

body::-webkit-scrollbar-track {
    background: var(--dark-purple);
}


body::-webkit-scrollbar {
    width: 0.2rem;
}

body::-webkit-scrollbar-thumb {
    background: linear-gradient(0deg,#ffffff00 , var(--orange) 80%, #ffffff00);
}

.title,
.sub-heading {
    display: fixed;
    text-align: center;
    font-family: 'Lato';
    text-transform: uppercase;
    font-weight: 300;
    color: var(--pink);
    margin-top: 3rem;
}

.sub-heading {
    font-size: 1rem;
}

.description {
    font-family: 'Lato';
    word-spacing: 0.1rem;
    letter-spacing: 0.5px;
    line-height: 2;
}

.highlight {
    color: var(--pink);
}

.line-break {
    display: block;
    background: linear-gradient(90deg,#ffffff00 , var(--orange) 40%,var(--orange) 60%, #ffffff00);
    height: 0.15rem;
}

.btn {
    font-family: 'Lato';
    font-size: 18px;
    border: solid;
    border-radius: 1.5rem;
    border-width: 0;
    border-color: var(--pink);
    padding: 0.2rem;
    background-color: var(--pink);
    cursor: pointer;
    transition: 300ms;
}

.btn:hover {
    background-color: var(--dark-purple);
    color: white;
    border-width: 0.1rem;
    border-color: var(--orange);
}