:root {
    
    --transition-speed: 600ms;
}

main {
    margin-left: 6rem;
    padding: 1rem;
}


.navbar{
    position: fixed;
    background-color: #292427;
    z-index: 100;
}

/* all nav elements */
.nav {
    position: fixed;
    display: flex;
    z-index: 100;
}


.nav-link{
    display: flex;
    align-items: center;
    filter: grayscale(100%) opacity(0.4);
    background: none;
    transition: var(--transition-speed);
}

.nav-link:hover {
    filter: grayscale(0%) opacity(1);
    /* background: var(--pink-transparent); */
}

.nav-link.current {
    filter: grayscale(0%) opacity(1);
    
}

@media only screen and (min-width: 600px){
    .navbar{
        top: 0;
        width: 5rem;
        height: 100vh;
    }

    .nav {
        top: 2vh;
        flex-direction: column;
        align-items: center;
    }

    .icon{
        margin-left:1rem;
        width: 3rem;
    }

    .page-title {
        font-family: 'Lato';
        font-size: 1.5rem;
        font-weight: 300;
        text-transform: uppercase;
        color:var(--pink);
        cursor: default;
        position: fixed;
        margin-left: 6rem;
        transition: var(--transition-speed);
        height: 5.5rem;
        align-items: center;
        display: none;
    }

    .nav-link{
        height: 5.5rem;
        width: 15rem;
    }
    
    .nav-link:not(.current) .page-title{
        transition: var(--transition-speed);
        display:none;
    }

    .nav-link:hover{
        background-image: linear-gradient(90deg,var(--pink-transparent)10%, #00000040 50%, #ffffff00);
    }
    
    .nav-link:hover .page-title{
        display: flex;
        animation: slidein 500ms;
    }


    /*would rather have it wipe in*/
    @keyframes slidein {
        0% { margin-left:-0px; opacity:0; }
    
        100% { margin-left:6rem; opacity: 1; }
    }

}

@media only screen and (max-width: 600px){
    .navbar{
        bottom: 0;
        width: 100vw;
        height: 3.5rem;
    
    }

    .nav {
        bottom: 0;
        flex-direction: row;
        width: 100vw;
        justify-content: center;
    }

    .icon{
        margin-left:1rem;
        margin-bottom:-1.5rem;
        width: 2.5rem;
    }

    .nav-link{
        height: 5rem;
        width: 5rem;
    }

    .page-title{
        display: none;
    }

}




