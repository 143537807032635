.cv-btn-container {
    display: flex;
    justify-content: center;
    margin-top: 3rem;
    text-decoration: none;
}


.cv-btn {
    width: 10rem;
    height: 3rem;
}