:root {
    --pink: #ECCCFF;
}

#aboutBodyContainer {
    margin-bottom: 5rem;
    margin-left: 16vw;
    width: 70vw;
    
}


#profilePic {
    display: block;
    width: 350px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 3rem;
    transform: translateY(-1rem);
}

.intro {
   text-align: right;
   
   
}

.intro_names{
    
    font-family: 'Poppins';
    font-size: 1.5rem;
    color: var(--orange);
    height: 3rem;
    overflow: hidden;
    margin-bottom: 2rem;
    
    
    

}

.name{
    animation: switchNames 20s ease infinite;
    
}

.description{
    text-align: right;
}


#bio{
    transform: translateY(-1rem);
}

.link_container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10rem;
    gap: 1rem;
    
    
    
}

.about_icon {
    transition: 200ms;
}

.about_icon:hover {
    filter: hue-rotate(100deg) saturate(5);
}

.vsco {
    width: 55px;
}

@media only screen and (min-width: 600px){

    @keyframes switchNames {
        0%,
        5%,
        10%,
        15%,
        100%{
            transform: translateY(-1rem);
        }
        20% {
            transform: translateY(-5rem);
        }
        25% {
            transform: translateY(-5rem);
        }
        30% {
            transform: translateY(-8.5rem);
        }
        35% {
            transform: translateY(-8.5rem);
        }
        40% {
            transform: translateY(-12.25rem);
        }
        45% {
            transform: translateY(-12.25rem);
        }
        50% {
            transform: translateY(-16rem);
        }
        55% {
            transform: translateY(-16rem);
        }
        60% {
            transform: translateY(-19.5rem);
        }
        65% {
            transform: translateY(-19.5rem);
        }
        70% {
            transform: translateY(-23.5rem);
        }
        75% {
            transform: translateY(-23.5rem);
        }
        80% {
            transform: translateY(-27rem);
        }
        85% {
            transform: translateY(-27rem);
        }
        90% {
            transform: translateY(-31rem);
        }
        95% {
            transform: translateY(-31rem);
        }
    } 
}

@media only screen and (max-width: 600px){

    @keyframes switchNames {
        0%,
        5%,
        10%,
        15%,
        100%{
            transform: translateY(-1.5rem);
        }
        20% {
            transform: translateY(-5.25rem);
        }
        25% {
            transform: translateY(-5.25rem);
        }
        30% {
            transform: translateY(-9rem);
        }
        35% {
            transform: translateY(-9rem);
        }
        40% {
            transform: translateY(-13.25rem);
        }
        45% {
            transform: translateY(-13.25rem);
        }
        50% {
            transform: translateY(-16.75rem);
        }
        55% {
            transform: translateY(-16.75rem);
        }
        60% {
            transform: translateY(-20.75rem);
        }
        65% {
            transform: translateY(-20.75rem);
        }
        70% {
            transform: translateY(-24.75rem);
        }
        75% {
            transform: translateY(-24.75rem);
        }
        80% {
            transform: translateY(-28.5rem);
        }
        85% {
            transform: translateY(-28.5rem);
        }
        90% {
            transform: translateY(-32.75rem);
        }
        95% {
            transform: translateY(-32.75rem);
        }
    } 
}

