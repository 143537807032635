#devTitle {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-right: 2rem;
}

#name {
    font-family: 'Poppins';
}

#period {
    color: var(--orange);
}

#title {
    font-family: 'Lato';
    font-weight: 300;
    text-transform: uppercase;
    letter-spacing: 5px;
    font-size: 12px;
    color: var(--pink);
    transform: translateY(-1.8rem);
}